<template>
  <v-app-bar
      extended
      flat
      :class="websiteData.style"
      dark
      elevation="0"
  >

    <GoHomeNavbar @onDecideChanges="onDecideChanges" :madeChanges="madeChanges"></GoHomeNavbar>

    <v-spacer></v-spacer>



  <!--v-if="websiteData.pro && websiteData.pro === true"-->
    <v-dialog
        v-model="pickingStyle"
        :max-width="maxWidthStylePicker"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            text
            class="mr-md-2"
            v-bind="attrs"
            v-on="on"
        >
          <v-icon class="mr-md-1">mdi-palette-outline</v-icon>
          Style
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline justify-center">
          Choose your style
        </v-card-title>
        <v-card-text>
          <v-select
              @change="onStyleSelected"
              v-model="websiteData.style"
              :items="styleList"
              label="Choose a style for your web"
              solo
          ></v-select>
        </v-card-text>
      </v-card>
    </v-dialog>




    <v-btn
        class="mr-md-2"
        :loading="saving"
        @click="save">
      Save
    </v-btn>


    <v-btn
        v-if="!showOverflow"
        text
        class="mr-md-2 mt-2"
        @click="seeWebsiteAsVisitor">
      See website as visitor
    </v-btn>


    <v-btn text class="mr-15" @click="logOut" v-if="!showOverflow">
      log out
    </v-btn>



    <v-menu
        v-if="showOverflow"
        left
        bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            :class="mr"
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="seeWebsiteAsVisitor">
          <v-list-item-title class="text-left">See website as visitor</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="logOut">
          <v-list-item-title class="text-left">log out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>



  </v-app-bar>
</template>

<script>
import {auth, storage, usersCollection} from "../firebase";
import firebase from "firebase";
import GoHomeNavbar from "./GoHomeNavbar";
import {styleList} from "../styleData"

export default {
  name: "ProfileNavbar",
  components: {GoHomeNavbar},
  props: ['websiteData', 'imageData', 'cvData', 'madeChanges'],
  data() {
    return {
      saving: false,
      pickingStyle: false,
      styleList: styleList
    }
  },
  computed: {
    showOverflow() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
      return false
    },
    mr() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return ''
        case 'sm': return 'mr-6'
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
      return false
    },
    maxWidthStylePicker() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '300px'
        case 'md': return '300px'
        case 'lg': return '300px'
        case 'xl': return '300px'
      }
      return '300px'
    }/*,
    navStyle() {
      return styleData[this.websiteData.style].navStyle
    }*/
  },
  methods: {
    onDecideChanges() {
      this.$emit('onDecideChanges')
    },
    logOut() {
      let router = this.$router;
      firebase.auth().signOut().then(function() {
        router.push('/')
      }, function(error) {
        this.$emit('onerror','Log Out Error: ' + error)
      });
    },
    seeWebsiteAsVisitor() {
      let routeData = this.$router.resolve('/'+this.websiteData.alias);
      window.open(routeData.href, '_blank');
    },
    onStyleSelected() {
      this.pickingStyle = false
      this.$emit('styleChanged')
    },
    updateDB() {
      let component = this

      usersCollection.doc(auth.currentUser.uid).update({
        show_name: component.websiteData.show_name,
        alias: component.websiteData.alias,
        bio: component.websiteData.bio,
        email: component.websiteData.email,
        phone: component.websiteData.phone,
        location: component.websiteData.location,
        image_name: component.websiteData.image_name,
        links: component.websiteData.shownLinks,
        style: component.websiteData.style
      })
      .then(function () {
        component.saving = false
        component.$emit('saved')
      })
      .catch(function (error) {
        component.saving = false
        component.$emit('onerror', 'Error when trying to save data. Please try again. ' + error)
      });
    },
    validLinks(links) {
      let i
      for (i = 0; i < links.length; i++) {
        if(links[i].linkUrl.trim().length === 0 || links[i].linkName.trim().length === 0) {
          this.$emit('onerror', 'Links cannot be empty. If you don\'t want to display a link, please delete it.')
          return false
        }
        if(!(links[i].linkUrl.toLowerCase().startsWith("http://") || links[i].linkUrl.toLowerCase().startsWith('https://'))) {
          this.$emit('onerror', 'Links must start with http:// or https://')
          return false
        }
      }
      return true
    },
    saveCV(component) {

      //if(component.websiteData.pro && component.websiteData.pro === true) {

      //}
      if(component.cvData.oldCvUrl.length > 0 && component.cvData.currentCvUrl.length === 0) {
          //Borrar CV
          storage.ref('cv/' + auth.currentUser.uid + '.pdf').delete().then(function () {
              // File deleted successfully
              component.cvData.oldImageUrl = ''
              component.updateDB()
          }).catch(function (error) {
              component.saving = false
              component.$emit('onerror', 'Error when trying to delete CV/resume. Please try again. ' + error)
          });
      } else if(component.cvData.newCvUrl.length > 0) {
          //Subimos CV
          storage.ref('cv/' + auth.currentUser.uid + '.pdf').put(this.cvData.newCvFile).then(function () {
              component.updateDB()
          }).catch(function (error) {
              component.saving = false
              component.$emit('onerror', 'Error when trying to upload CV/resume. Please try again. ' + error)
          });
      } else {
          component.updateDB()
      }

    },
    save() {
      if(this.websiteData != null) {
        if(this.websiteData.show_name.trim().length > 0) {
          if(this.websiteData.bio.trim().length >= 50) {
            if(this.websiteData.shownLinks.length === 0 || (this.websiteData.shownLinks.length > 0 && this.validLinks(this.websiteData.shownLinks))) {
              this.saving = true;
              let component = this
              //Primero veamos si hay que borrar una foto
              if(this.imageData.oldImageUrl.length > 0 && this.imageData.currentImageUrl.length === 0) {
                //Borramos archivo
                storage.ref('images/' + auth.currentUser.uid + '/' + this.imageData.oldImageName).delete().then(function () {
                  // File deleted successfully
                  component.websiteData.image_name = ''
                  component.imageData.oldImageUrl = ''
                  component.imageData.oldImageName = ''
                  component.saveCV(component)
                }).catch(function (error) {
                  component.saving = false
                  component.$emit('onerror', 'Error when trying to delete image. Please try again. ' + error)
                });
              } else if(this.imageData.newImageUrl.length > 0) {
                //Nueva foto
                //let extension = component.imageData.newImageName.split('.').pop()
                let newName = auth.currentUser.uid// + '.' + extension
                storage.ref('images/' + auth.currentUser.uid + '/' + newName).put(this.imageData.newImageFile).then(function () {
                  //Joya, falta poner el nombre en el atributo de la DB

                  component.websiteData.image_name = newName
                  component.saveCV(component)
                }).catch(function (error) {
                  component.saving = false
                  component.$emit('onerror', 'Error when trying to upload image. Please try again. ' + error)
                });
              } else {
                component.saveCV(component)
              }
            } else {
              this.$emit('onerror', 'The provided links are wrong')
            }
          } else {
            this.$emit('onerror', '"Bio" field must have minimum 50 characters')
          }
        } else {
          this.$emit('onerror', '"Name" field cannot be empty')
        }

      }

    }
  }
}
</script>

<style scoped>
  .Natural {
    background: linear-gradient(180deg, #59b9d6 0%, rgb(129 222 209) 100%);
  }
  .Night {
    background: linear-gradient(rgb(0 0 0) 0%, rgb(27 33 62) 100%);
  }
  .Pink {
    background: linear-gradient(rgb(195 125 204) 0%, rgb(247 120 255) 100%);
  }
</style>