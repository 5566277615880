/*import * as firebase from 'firebase/app'
import 'firebase/auth'*/
import firebase from "firebase";
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAsWDUC8f8Za8dgBMOH_J5I7uR88esjnZk",
    authDomain: "tzeani-5bae8.firebaseapp.com",
    projectId: "tzeani-5bae8",
    storageBucket: "tzeani-5bae8.appspot.com",
    messagingSenderId: "820022812294",
    appId: "1:820022812294:web:ff4044c44bf38eb240fb07",
    measurementId: "G-V1PJRXFQBS"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage();

// collection references
const usersCollection = db.collection('users')
const aliasCollection = db.collection('alias')

export {
    auth,
    db,
    storage,
    usersCollection,
    aliasCollection
}
/*
// export utils/refs
export {
    auth
    db,
    auth,
    usersCollection,
    postsCollection,
    commentsCollection,
    likesCollection
}*/

/*import * as firebase from 'firebase/app'
import 'firebase/auth'*/
//import 'firebase/firestore'

// firebase init - add your own config here
/*const firebaseConfig = {
    apiKey: "AIzaSyAsWDUC8f8Za8dgBMOH_J5I7uR88esjnZk",
    authDomain: "tzeani-5bae8.firebaseapp.com",
    projectId: "tzeani-5bae8",
    storageBucket: "tzeani-5bae8.appspot.com",
    messagingSenderId: "820022812294",
    appId: "1:820022812294:web:ff4044c44bf38eb240fb07",
    measurementId: "G-V1PJRXFQBS"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// FirebaseUI config.
var uiConfig = {
    signInSuccessUrl: '<url-to-redirect-to-on-success>',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
        //firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: '<your-tos-url>',
    // Privacy policy url/callback.
    privacyPolicyUrl: function() {
    window.location.assign('<your-privacy-policy-url>');
}
};

    // Initialize the FirebaseUI Widget using Firebase.
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    // The start method will wait until the DOM is loaded.
    ui.start('#firebaseui-auth-container', uiConfig);*/

// utils
/*const db = firebase.firestore()
const auth = firebase.auth()*/

// collection references
/*const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')*/

// export utils/refs
/*export {
    auth
    db,
    auth,
    usersCollection,
    postsCollection,
    commentsCollection,
    likesCollection
}*/