<template>
  <v-footer
      dark
      padless
  >
    <v-card
        class="flex"
        flat
        tile
    >
      <v-card-title class="teal">
        <strong class="subheading">Be the first to get the news!</strong>

        <v-spacer></v-spacer>




        <!--<v-btn
            class="mx-4"
            dark
            icon
        >
          <v-icon size="24px">mdi-facebook</v-icon>
        </v-btn>-->
        <v-btn
            class="mx-4"
            dark
            icon
            href="mailto:info@tzeani.com"
        >
          <v-icon size="24px">mdi-email-outline</v-icon>
        </v-btn>
        <v-btn
            class="mx-4"
            dark
            icon
            href="https://twitter.com/tzeanicom"
        >
          <v-icon size="24px">mdi-twitter</v-icon>
        </v-btn>
        <v-btn
            class="mx-4"
            dark
            icon
            href="https://instagram.com/tzeani"
        >
          <v-icon size="24px">mdi-instagram</v-icon>
        </v-btn>

      </v-card-title>

      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} — <strong>tzeani.com</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "PlatformFooter"
}
</script>

<style scoped>

</style>