<template>
  <v-footer
      padless
      absolute
  >
    <v-card
        flat
        tile
        width="100%"
        class="cyan darken-2 white--text text-center"
    ><!--blue-grey darken-3-->
      <!--<v-card-text>
        <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4"
            icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>-->

      <!--<v-divider></v-divider>-->

      <v-card-text class="white--text">
        Website created with <router-link class="white--text" to="/"><strong>tzeani.com</strong></router-link> <!--{{ new Date().getFullYear() }} — <strong>Vuetify</strong>-->
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "WebsiteFooter"
}
</script>

<style scoped>

</style>