<template>
  <v-toolbar-title :class="ml">

    <div>
      <v-btn
          class="mt-3"
          text
          x-large
          @click="clicked" >
        <v-img :class="imgLogo" src="../assets/logo_web.png" :max-width="imgLogoMaxWidth"  />
      </v-btn>

    </div>
  </v-toolbar-title>
</template>

<script>
export default {
  name: "GoHomeNavbar",
  props: ['madeChanges'],
  computed: {
    ml() {

      if(this.$route.name !== 'Home') {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'ml-n3'
          case 'sm': return 'ml-3'
          case 'md': return 'ml-10'
          case 'lg': return 'ml-15'
          case 'xl': return 'ml-15'
        }
        return 'ml-15'
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'ml-3'
          case 'sm': return 'ml-3'
          case 'md': return 'ml-10'
          case 'lg': return 'ml-15'
          case 'xl': return 'ml-15'
        }
        return 'ml-15'
      }

    },
    imgLogo() {
      if(this.$route.name !== 'Home') {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'ml-n3'
        }
        return ''
      } else {
        return ''
      }

    },
    imgLogoMaxWidth() {
      if(this.$route.name !== 'Home') {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '70px'
          case 'sm': return '100px'
          case 'md': return '100px'
          case 'lg': return '100px'
          case 'xl': return '100px'
        }
        return '100px'
      } else {
        return '100px'
      }

    }
  },
  methods: {
    clicked() {
      if(!this.madeChanges) {
        this.$router.push('/')
      } else {
        this.$emit('onDecideChanges')
      }

    }
  }
}
</script>

<style scoped>

</style>