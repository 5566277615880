<template>
  <v-card class="pt-xl-15 pt-lg-15 pt-md-7 pt-sm-7 pt-7" elevation="0">
    <h2
        class="text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h5 text-center font-weight-medium">
      Who are you? What is your background?<br>What about your interests?
    </h2>
    <h3
        class="text-xl-h5 text-lg-h5 text-md-h5 text-sm-h6 text-h6 text-center mt-xl-9 mt-lg-9 mt-md-5 mt-sm-4 mt-4 font-weight-regular">
      Answer it to the world with an elegant, fresh and new website.
    </h3>
    <v-container
      class="mt-xl-15 mt-lg-15 mt-md-4 mt-sm-4 mt-4 mb-15 pt-xl-5 pt-lg-5">
      <v-row class="justify-center mb-5" no-gutters
      >
        <v-col
          class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-sm-5 mb-5 px-xl-5 px-lg-5 px-md-2 px-sm-6 px-6">
          <v-card
              outlined
              shaped
              elevation="5"
            class="py-xl-5 py-lg-5 py-md-2 py-sm-5 py-5 px-xl-5 px-lg-5 px-md-1 px-sm-4 px-4">
            <v-icon
                class="text-xl-h1 text-lg-h1 text-md-h2 text-sm-h2 text-h2 mb-xl-3 mb-lg-3 mb-md-1 mb-sm-3 mb-4"
            >mdi-label-multiple-outline</v-icon>
            <div
                class="text-xl-h5 text-lg-h5 text-md-h6 text-sm-h6 text-h5 font-weight-medium">
              It's free, you have no excuses.
            </div>
            <div
                class="text-xl-h6 text-lg-h6 text-md-body-1 text-sm-body-1 text-h6 font-weight-regular mt-xl-15 mt-lg-15 mt-md-14 mt-sm-14 mt-5">
              Begin without any kind of charges or fees. In 2 minutes you can start your own website.
            </div>
          </v-card>
        </v-col>
        <v-col
            class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-sm-5 mb-5 px-xl-5 px-lg-5 px-md-2 px-sm-6 px-6">
          <v-card
              outlined
              shaped
              elevation="5"
              class="py-xl-5 py-lg-5 py-md-2 py-sm-5 py-5 px-xl-5 px-lg-5 px-md-1 px-sm-4 px-4">
            <v-icon
                class="text-xl-h1 text-lg-h1 text-md-h2 text-sm-h2 text-h2 mb-xl-3 mb-lg-3 mb-md-1 mb-sm-3 mb-4"
              >mdi-lightbulb-on-outline</v-icon>
            <div
                class="text-xl-h5 text-lg-h5 text-md-h6 text-sm-h6 text-h5 font-weight-medium">
              Explode your potential, don't lose time.
            </div>
            <div
                class="text-xl-h6 text-lg-h6 text-md-body-1 text-sm-body-1 text-h6 font-weight-regular mt-xl-15 mt-lg-15 mt-md-14 mt-sm-14 mt-5">
              Everybody has online presence. You may be an outstanding professional, but if you aren't online, you don't exist. It's easy.
            </div>
          </v-card>
        </v-col>
        <v-col
            class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-sm-5 mb-5 px-xl-5 px-lg-5 px-md-2 px-sm-6 px-6">
          <v-card
              outlined
              shaped
              elevation="5"
              class="py-xl-5 py-lg-5 py-md-2 py-sm-5 py-5 px-xl-5 px-lg-5 px-md-1 px-sm-4 px-4">
            <v-icon
                class="text-xl-h1 text-lg-h1 text-md-h2 text-sm-h2 text-h2 mb-xl-3 mb-lg-3 mb-md-1 mb-sm-3 mb-4"
            >mdi-code-braces</v-icon>
            <div
                class="text-xl-h5 text-lg-h5 text-md-h6 text-sm-h6 text-h5 font-weight-medium">
              No technical knowledge required.
            </div>
            <div
                class="text-xl-h6 text-lg-h6 text-md-body-1 text-sm-body-1 text-h6 font-weight-regular mt-xl-15 mt-lg-15 mt-md-14 mt-sm-14 mt-5">
              Forget about coding or setting up stressful and hard things. We simplify everything. Just focus on your life, we take care of the rest.
            </div>
          </v-card>
        </v-col>
        <v-col
            class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-sm-5 mb-5 px-xl-5 px-lg-5 px-md-2 px-sm-6 px-6">
          <v-card
              outlined
              shaped
              elevation="5"
              class="py-xl-5 py-lg-5 py-md-2 py-sm-5 py-5 px-xl-5 px-lg-5 px-md-1 px-sm-4 px-4">
            <!--<v-icon
                class="text-xl-h1 text-lg-h1 text-md-h2 text-sm-h2 text-h2 mb-xl-3 mb-lg-3 mb-md-1 mb-sm-3 mb-4"
            >mdi-monitor-off</v-icon>
            <div
                class="text-xl-h5 text-lg-h5 text-md-h6 text-sm-h6 text-h5 font-weight-medium">
              Screen-reader ready.
            </div>
            <div
                class="text-xl-h6 text-lg-h6 text-md-body-1 text-sm-body-1 text-h6 font-weight-regular mt-xl-15 mt-lg-15 mt-md-14 mt-sm-14 mt-5">
              We want to make all websites accesible for everybody. That's why your website will be ready for people with vision problems.
            </div>-->
            <v-icon
                class="text-xl-h1 text-lg-h1 text-md-h2 text-sm-h2 text-h2 mb-xl-3 mb-lg-3 mb-md-1 mb-sm-3 mb-4"
            >mdi-responsive</v-icon>
            <div
                class="text-xl-h5 text-lg-h5 text-md-h6 text-sm-h6 text-h5 font-weight-medium">
              Responsive design
            </div>
            <div
                class="text-xl-h6 text-lg-h6 text-md-body-1 text-sm-body-1 text-h6 font-weight-regular mt-xl-16 mt-lg-16 mt-md-15 mt-sm-15 mt-6">
              Your website performs amazingly well in Desktop, Tablet and Mobile. It adapts to every screen size to fit perfectly.
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "about"
}
</script>
