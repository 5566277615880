<template>
  <div
    class="pt-10">
    <div
      class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h5 text-h5 font-weight-bold">
      Do you need more features?
    </div>
    <div
      class="text-xl-h6 text-lg-h6 text-md-body-1 text-sm-body-1 text-body-1 mb-xl-10 mb-lg-10 mb-md-10 mb-sm-10 mb-10">
      Your website is amazing, but it can get even better with our <span class="font-weight-bold">PRO plan</span>.
    </div>

    <v-card
        elevation="5"
        class="mx-auto py-xl-4 py-lg-4 py-md-4 py-sm-3 py-3 mb-xl-16 mb-lg-16 mb-md-16 mb-sm-10 mb-10"
        :max-width="get1CardWidth">
      <v-card
          v-for="(feature, idx) in features"
          v-bind:key="idx"
          elevation="0"
      >
        <div class="d-flex flex-no-wrap ">
          <v-avatar
              class="ml-3"
              size="125"
              tile
          >
            <v-icon x-large>{{feature.icon}}</v-icon>
          </v-avatar>
          <div>
            <v-card-title
                class="headline font-weight-bold text-break text-left"
            >{{ feature.title }}</v-card-title>
            <v-card-subtitle class="text-left">{{ feature.subtitle }}</v-card-subtitle>
          </div>
        </div>
      </v-card>
    </v-card>

    <div
      class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h5 text-h5">
      For just <span class="font-weight-black">$4/mo</span>&nbsp;<span class="text-subtitle-2">*</span>
    </div>

    <v-btn
        @click="$router.push('/pro')"
        v-bind="attrs"
        v-on="on"
        x-large
        rounded
        class="my-xl-6 my-lg-6 my-md-6 my-sm-6 my-6 indigo darken-2 white--text font-weight-bold text-none text-xl-h5 text-lg-h5 text-md-h6 text-sm-h6 text-h6">
      Become PRO now <v-icon class="ml-2" color="yellow">mdi-lightbulb-on</v-icon>
    </v-btn>





    <div class="text-subtitle-2">* Annual payment. Value expressed in US Dollars.</div>

  </div>
</template>

<script>

export default {
  name: "GoPro",
  data() {
    return {
      features: [
        {
          icon: 'mdi-chat',
          title: 'Full contact details',
          subtitle: 'Include an Email, Phone number and Location.'
        },
        {
          icon: 'mdi-share-variant',
          title: 'Unlimited links',
          subtitle: 'Upload as many links as you wish. No limits. Link to every single social media or website you want.'
        },
        {
          icon: 'mdi-palette',
          title: 'Customize your website\'s style',
          subtitle: 'Choose among a variety of styles to personalize the appearance.'
        },
        {
          icon: 'mdi-clipboard-account-outline',
          title: 'Upload your CV/Resume',
          subtitle: 'It\'s extremely useful to let people download your CV/Resume. Upload yours so everybody can read it.'
        },
        {
          icon: 'mdi-bullhorn',
          title: 'Footer removed',
          subtitle: 'Free websites include a footer with a link to tzeani. Remove it to give an even more professional image.'
        },
        {
          icon: 'mdi-emoticon',
          title: 'More features coming!',
          subtitle: 'We\'re constantly working to improve tzeani. PRO users will have these features and others more that are still in the oven!'
        }
        //Estadísticas
      ]
    }
  },
  computed: {
    get1CardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '90%'
        case 'md': return '700px'
        case 'lg': return '700px'
        case 'xl': return '700px'
      }
      return '700px'
    }
  }
}
</script>

<style scoped>

</style>