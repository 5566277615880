<template>
  <Home :Title="notFoundTitle" :Subtitle="notFoundSubtitle"></Home>
</template>

<script>


import Home from "./Home";
export default {
  metaInfo: {
    title: 'tzeani | Create fast & simple website',
    meta: [
      { name: 'description', content: 'Less than 2 minutes is the time it takes you to create a website with tzeani.com. Free, fast, elegant, and simple.' },
      { name: 'robots', content: 'noindex, nofollow' }
    ]
  },
  name: "WebsiteNotFound",
  components: {
    Home
  },
  data() {
    return {
      notFoundTitle: 'Oops, page not found.',
      notFoundSubtitle: 'But hey, it can be yours! What are you waiting for?'
    }
  }
}
</script>

<style scoped>

</style>