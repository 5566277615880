let styleData = {}

/*styleData["Natural"] = {
    navStyle: 'background: linear-gradient(180deg, #59b9d6 0%, rgb(129 222 209) 100%);',
    pencilStyle: 'color: rgba(0, 0, 0, 0.54);',
    mainStyle: 'background-color: #ffffff;'
}
styleData["Night"] = {
    navStyle: 'background: linear-gradient(rgb(0 0 0) 0%, rgb(27 33 62) 100%);',
    pencilStyle: 'color: #8e7d7d;',
    mainStyle: 'background-color: #1a203d;'
}*/


let styleList = [
    'Natural',
    'Night',
    'Pink'
]

export {
    styleData,
    styleList
}