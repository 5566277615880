<template>
  <v-card
      class="fill-height  "
      elevation="0">

    <SignedInNavbar></SignedInNavbar>

    <v-card
        class="mx-auto py-10 mt-xl-n16 mt-lg-n16 mt-md-n11 mt-sm-n11 mt-n11 mb-15 px-2"
        :max-width="getCardWidth">
        <v-btn
          @click="$router.push('/me')"
          text>
          <v-icon class="mr-2" small>mdi-arrow-left</v-icon>Return
        </v-btn>
        <v-card-title class="text-xl-h3 text-lg-h3 text-md-h3 text-sm-h3 text-h4 font-weight-medium justify-center">
          Becoming PRO
        </v-card-title>
        <v-card-text class="text-xl-h5 text-lg-h5 text-md-h5 text-sm-h5 text-h6 mt-6">
          You pay <span class="font-weight-bold">$48</span> for a <span class="font-weight-bold">12 months PRO membership</span> that includes everything you read before. Activation can take up to 24hs.
        </v-card-text>

        <PayPal
            amount="48.00"
            currency="USD"
            :client="paypal"
            :items="myItems"
            :experience="experienceOptions"
            :button-style="myStyle"
            @payment-completed="onPaymentCompleted">
        </PayPal>


    </v-card>




    <v-dialog
        persistent
        v-model="dialogResult"
        :width="get2CardWidth"
    >

      <v-card>
        <v-card-title class="headline Natural justify-center">
          Payment successful
        </v-card-title>

        <v-card-text class="pt-8 text-h6">
          Your payment has been sent. As soon as we receive the confirmation your account will turn PRO. Consider that this process can take up to 24 hs.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialogResult = false; $router.push('/me')"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




  </v-card>
</template>

<script>
import SignedInNavbar from "../components/signedInNavbar";
import PayPal from 'vue-paypal-checkout'
import { auth } from '../firebase'

export default {
  metaInfo: {
    title: 'tzeani | Create fast & simple website',
    meta: [
      { name: 'description', content: 'Less than 2 minutes is the time it takes you to create a website with tzeani.com. Free, fast, elegant, and simple.' },
      { name: 'robots', content: 'noindex, nofollow' }
    ]
  },
  name: "Pro",
  components: { SignedInNavbar, PayPal},
  data() {
    return {
      dialogResult: false,
      paypal: {
        sandbox: 'AUbLNevp_BwqtWpK4z1MU6IJzt_c1VmSKh5dG2yaZCGhyGUR7iGVrczYWnz0cDyuwCYtJqYUWd0yBQQv',
        production: 'AR7Xsg02KXR1m1Zac66Wa0529zbBpTO8CtXgDtdMVvy3eSnZEWUASdPsyoWzX846vNjIHkRbBtnEIiaj'
      },
      myItems: [
        {
          "name": "PRO Plan uid: " + auth.currentUser.uid,
          "description": "12 months of PRO Plan at tzeani",
          "quantity": "1",
          "price": "48",
          "currency": "USD"
        }
      ],
      myStyle:
      {
        label: 'checkout',
        size:  'responsive',
        shape: 'pill',
        color: 'gold'
      },
      experienceOptions: {
        input_fields: {
          no_shipping: 1
        },
        presentation: {
          logo_image: "https://tzeani.com/logo.jpg"
        }
      }
    }
  },
  methods: {
    onPaymentCompleted(payload) {
      if(payload.state === "approved") {
        this.dialogResult = true
      }
    }
  },
  computed: {
    getCardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '500px'
        case 'md': return '700px'
        case 'lg': return '700px'
        case 'xl': return '700px'
      }
      return '700px'
    },
    get2CardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '80%'
        case 'sm': return '80%'
        case 'md': return '600px'
        case 'lg': return '600px'
        case 'xl': return '600px'
      }
      return '600px'
    }
  }
}
</script>

<style scoped>

.Natural {
  background: linear-gradient(180deg, #59b9d6 0%, rgb(129 222 209) 100%);
}

</style>