import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import {auth } from './firebase'
import VueMeta from 'vue-meta'



Vue.config.productionTip = false;

Vue.use(VueMeta)

let app
auth.onAuthStateChanged(() => {

    if (!app) {
        app = new Vue({
            vuetify,
            router,
            created: function() {
                Vue.prototype.$publicUrl = 'https://tzeani.com'
            },
            render: h => h(App)
        }).$mount('#app')
    }



})


/*
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')*/


