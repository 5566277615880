<template>
  <v-card
    class="welcome py-md-15 py-sm-15 py-13" elevation="0">
    <v-container
      class="text-left">
      <v-row class="pt-xl-15 pt-lg-5 pt-md-4 pl-xl-0 pl-lg-0 pl-md-0 pl-sm-3 pl-3">
        <v-col
          class="col-xl-6 col-lg-8 col-md-8 col-sm-10 col-12">
          <div
              id="tituloHome"
              class="text-xl-h1 text-lg-h1 text-md-h2 text-sm-h4 text-h3 white--text">
              {{customTitle}}
          </div>
          <div
              id="subtituloHome"
              class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-subtitle-1 text-h6 blue-grey--text text--lighten-4 mt-xl-15 mt-lg-8 mt-md-8 mt-sm-4 mt-5">
            {{customSubtitle}}
          </div>
          <v-btn
              elevation="20"
              outlined
              x-large
              class="text-none blue-grey darken-2 white--text text-xl-h4 text-lg-h4 text-md-h5 text-sm-subtitle-1 text-body-2 mt-xl-10 mt-lg-10 mt-md-10 mt-sm-4 mt-4 py-xl-12 py-lg-10 py-md-7 py-sm-5 py-4 px-xl-15 px-lg-10 px-md-6 px-sm-6 px-5"
              style="border-color: #000!important; border-style: solid; border-width: 1px;"
              @click="goToSignUp"
          >Create your website for free</v-btn>
          <br>
          <v-row
            class="mt-xl-7 mt-lg-7 mt-md-7 mt-sm-5 mt-5 mb-10 px-3 blue-grey--text text--lighten-4">
            <p class="mt-xl-1 mt-lg-1 mt-md-2 mt-sm-2 mt-2 text-xl-body-1 text-lg-body-1 text-md-body-2 text-sm-body-2 text-body-2">A fresh, simple and elegant website.</p>
            <v-btn
                rounded
                outlined
                class="text-none blue-grey--text text--lighten-4 ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2 text-xl-subtitle-1 text-lg-subtitle-1 text-md-subtitle-2 text-sm-body-2 text-body-2 pa-xl-4 pa-lg-4 pa-md-3 pa-sm-3 pa-3"
                @click="seeSampleWebsite">
              <v-icon left>
                mdi-eye
              </v-icon>
              See a sample website
            </v-btn>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

    </v-container>
  </v-card>
</template>

<script>

//import welcomeNavbar from '@/components/welcomeNavbar.vue'

export default {
  name: "welcome",
  props: ['customTitle', 'customSubtitle'],
  components: {
    //welcomeNavbar
  },
  methods: {
    goToSignUp() {
      this.$router.push('/signup').catch(() => {});
    },
    seeSampleWebsite() {
      let routeData = this.$router.resolve('/sample');
      window.open(routeData.href, '_blank');
    }
  }
}
</script>

<style>
  .welcome {
    background: linear-gradient(180deg,#262626,#929292);
  }



</style>