<template>
  <!--<div
    class="fill-height">-->
  <v-card class="fill-height">
    <v-progress-circular
        class="mt-15"
        indeterminate
        color="blue-grey"
        :size="100"
        v-if="!loaded"
    ></v-progress-circular>
    <v-card
        class="fill-height pb-15"
        elevation="0"
        v-else-if="websiteData != null">
      <WebsiteNavbar
        :websiteStyle="websiteData.style"></WebsiteNavbar>


      <v-avatar v-if="websiteData.image_name.length > 0" class="text-center mx-auto py-0 mt-xl-n16 mt-lg-n16 mt-md-n16 mt-sm-n16 mt-n16" :size="getAvatarSize">
        <v-img
          :src="websiteData.image_url"
          :alt="websiteData.show_name"
        >
          <template v-slot:placeholder>
            <v-row
                v-if="websiteData.image_url.length === 0"
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="green"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-avatar>


      <v-card
          :elevation="showNameCardElevation"
          :class="showNameCardClass"
          :max-width="get1CardWidth" >
        {{websiteData.show_name}}
      </v-card>

      <v-container
          v-if="(websiteData.email && websiteData.email.trim().length > 0) || (websiteData.pro && websiteData.pro === true && websiteData.phone && websiteData.phone.trim().length > 0) || (websiteData.location && websiteData.location.trim().length > 0)"
          class="mt-xl-6 mt-lg-6 mt-md-6 mt-sm-3 mt-3"
          :style="getContactContainerStyle" >
        <v-row
          class="justify-center">
          <v-col
              v-if="websiteData.email && websiteData.email.trim().length > 0"
              title="Email"
              :class="contactColClass">
              <v-icon color="blue-grey" class="mr-2">mdi-email</v-icon>
              {{ websiteData.email }}
          </v-col>
          <v-col
              title="Phone number"
              :class="contactColClass"
              v-if="websiteData.phone && websiteData.phone.trim().length > 0" >
            <v-icon color="light-blue accent-2" class="mr-2">mdi-cellphone</v-icon>
            {{ websiteData.phone }}
          </v-col>
          <v-col
              v-if="websiteData.location && websiteData.location.trim().length > 0"
              title="Location"
              :class="contactColClass">
            <v-icon color="deep-orange lighten-1" class="mr-2">mdi-map-marker</v-icon>
            {{ websiteData.location }}
          </v-col>
        </v-row>
      </v-container>

      <v-card
        class="mx-auto py-xl-7 py-lg-7 py-md-7 py-sm-7 py-5 px-xl-7 px-lg-7 px-md-7 px-sm-7 px-3 mt-xl-12 mt-lg-12 mt-md-7 mt-sm-3 mt-3 text-xl-subtitle-1 text-lg-subtitle-1 text-md-subtitle-1 text-sm-subtitle-1 text-sm-subtitle-1 font-weight-medium text-left"
        style="border-radius: 10px; white-space: pre-line;"
        :max-width="get2CardWidth"
        elevation="8"
        shaped >
        {{ websiteData.bio }}
      </v-card>

      <div class="mt-xl-13 mt-lg-13 mt-md-13 mt-sm-9 mt-9" v-if="websiteData.cv_url.length > 0"><!--websiteData.pro && websiteData.pro === true && -->
        <div
            class="text-xl-h6 text-lg-h6 text-md-h6 text-sm-body-1 text-body-1 mt-xl-10 mt-lg-10 mt-md-10 mt-sm-7 mt-7 font-weight-medium mx-2">
          <v-icon large>mdi-clipboard-account-outline</v-icon>
          CV/Resume
          <v-btn
              title="Download CV/Resume"
              :href="websiteData.cv_url"
              target="_blank"
              icon
              elevation="2"
              class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2 ml-2">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>

      </div>



      <v-card
          elevation="0"
          class="mx-auto mt-xl-16 mt-lg-16 mt-md-10 mt-sm-10 mt-10 mb-16 px-3"
          :max-width="get2CardWidth" >
        <div
          class="mb-7"
          v-for="(link, idx) in websiteData.links"
          v-bind:key="idx">
          <v-row>
            <v-card
                  elevation="7"
                  class="py-2"
                  width="100%">
              <a class="text-decoration-none font-weight-bold" style="color: #000000de;" :href="link.linkUrl" target="_BLANK">
                <v-icon class="justify-start mr-2">{{link.linkType}}</v-icon>
                {{link.linkName ? link.linkName : link.linkUrl}}
              </a>
            </v-card>
          </v-row>
        </div>
      </v-card>

      <WebsiteFooter></WebsiteFooter>
    </v-card>
    <WebsiteNotFound
        v-else-if="websiteData == null">
    </WebsiteNotFound>
    <v-snackbar
        v-model="isError"
        :timeout="4000"
        top
        color="error"
    >{{errorValue}}</v-snackbar>
  <!--</div>-->
  </v-card>
</template>

<script>
import {storage, usersCollection} from '../firebase'
import WebsiteNotFound from "./WebsiteNotFound";
import WebsiteNavbar from "../components/WebsiteNavbar";
import WebsiteFooter from "../components/WebsiteFooter";

let titulo = '', descripcion =''

export default {
  metaInfo: {
    title: titulo,
    meta: [
      { name: 'description', content: descripcion },
      { name: 'robots', content: 'index, nofollow' }
    ]
  },
  name: "Website",
  components: {WebsiteFooter, WebsiteNavbar, WebsiteNotFound},
  data() {
    return {
      alias: this.$route.params.alias,
      websiteData: null,
      loaded: false,
      isError: false,
      errorValue: ''

    }
  },
  methods: {
    setWebsiteData(websiteData) {
      let aux = websiteData
      aux.image_url = ''
      aux.cv_url = ''
      this.websiteData = aux

      titulo = this.websiteData.show_name
      descripcion = this.websiteData.bio.substring(0, 147)
      descripcion += "..."
    },
    setLoaded() {
      this.loaded = true
    },
    showError(error) {
      this.isError = true
      this.errorValue = error
    },
    beautyLink(link) {
      if(link.toLowerCase().startsWith("http://"))
        return link.substring(7)
      else
        return link.substring(8)
    }
  },
  mounted() {
    let component = this
    usersCollection.where("alias", "==", this.alias.toLowerCase()).limit(1).get()
      .then(function(querySnapshot) {
        let gottenId = null
        querySnapshot.forEach(function(doc) {
          gottenId = doc.id
          component.setWebsiteData(doc.data())
        });
        component.setLoaded()



        if(component.websiteData.image_name.length > 0) {
          storage.ref('images/' + gottenId + '/' + component.websiteData.image_name).getDownloadURL().then(function(url) {
            component.websiteData.image_url = url
          }).catch(function(error) {
            //Error, o no existe la imagen
            component.showError(error)
          });
        }

        storage.ref('cv/' + gottenId + '.pdf').getDownloadURL().then(function(url) {
          component.websiteData.cv_url = url
        }).catch(function() {
          //No existe
        });
      })
      .catch(function(error) {
        this.showError("Error loading website: ", error)
        component.setLoaded()
      });

  },
  computed: {
    get1CardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '500px'
        case 'md': return '700px'
        case 'lg': return '700px'
        case 'xl': return '700px'
      }
      return '700px'
    },
    get2CardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '80%'
        case 'md': return '800px'
        case 'lg': return '1000px'
        case 'xl': return '1000px'
      }
      return '1000px'
    },
    getContactContainerStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'max-width: 90%'
        case 'sm': return 'max-width: 80%'
        case 'md': return 'max-width: 800px'
        case 'lg': return 'max-width: 1000px'
        case 'xl': return 'max-width: 1000px'
      }
      return 'max-width: 1000px'
    },
    getAvatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '120'
        case 'sm': return '120'
        case 'md': return '120'
        case 'lg': return '130'
        case 'xl': return '150'
      }
      return false
    },
    contactColClass() {
      let retornar = "py-xl-2 py-lg-2 py-md-2 py-sm-2 py-2 text-center text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-2 text-body-1"
      retornar += 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'
      return retornar
    },
    showNameCardClass() {
      if(this.websiteData.image_name.length > 0) {
        return 'text-xl-h4 text-lg-h4 text-md-h5 text-sm-h5 text-h5 font-weight-bold text-center mx-auto py-xl-7 py-lg-7 py-md-4 py-sm-4 py-3 px-4'
      } else {
        return 'text-xl-h4 text-lg-h4 text-md-h5 text-sm-h5 text-h5 font-weight-bold text-center mx-auto py-xl-7 py-lg-7 py-md-4 py-sm-4 py-3 px-4 mt-xl-n16 mt-lg-n16 mt-md-n11 mt-sm-n11 mt-n11'
      }
    },
    showNameCardElevation() {
      if(this.websiteData.image_name.length > 0) {
        return 0
      } else {
        return 3
      }
    }
  }
}
</script>

<style scoped>

</style>