<template>
  <v-card class="fill-height pb-16" elevation="0">
    <v-progress-circular
        class="mt-15"
        indeterminate
        color="blue-grey"
        :size="100"
        v-if="madeFirstStep===null"
    ></v-progress-circular>
    <FirstStep
        v-else-if="!madeFirstStep"
        @done="justDone">
    </FirstStep>
    <Profile
      :doc="loadedDoc"
      :oldImageUrl="oldImageUrl"
      :oldImageName="oldImageName"
      :oldCvUrl="oldCvUrl"
      v-else-if="madeFirstStep"></Profile>

    <v-dialog
        v-model="showWelcomeDialog"
        transition="dialog-top-transition"
        max-width="600"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
              color="primary"
              dark
          >
            <div
            class="text-xl-h6 text-lg-h6 text-md-h6 text-sm-h6 text-body-1 font-weight-medium">
              Congratulations! Your website is now online</div>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="dialog.value = false"
            >X</v-btn>
          </v-toolbar>
          <v-card-text class="px-xl-6 px-lg-6 px-md-6 px-sm-6 px-3 pb-15">
            <div class="text-xl-h5 text-lg-h5 text-md-h5 text-sm-h5 text-h6 my-10 text-center">Share your website with everybody</div>
            <v-text-field
                readonly
                class="text-center text-xl-h6 text-lg-h6 text-md-h6 text-sm-h6 text-h6"
                v-model="urlToShare"
                outlined
                prepend-icon="mdi-share-variant-outline"
                append-outer-icon="mdi-content-copy"
                @click:append-outer="copyUrl"
                ></v-text-field>
            <v-divider></v-divider>
            <v-row
              class="mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5 mt-5 justify-center">
              <ShareNetwork
                  class="text-decoration-none mr-2"
                  network="whatsapp"
                  :url="urlToShare"
                  :title="show_name"
                  :description="bio"
              >
                <v-icon
                    title="Share through Whatsapp"
                    x-large
                    color="green accent-3">mdi-whatsapp</v-icon>
              </ShareNetwork>
              <ShareNetwork
                  class="text-decoration-none ml-2 mr-2"
                  network="facebook"
                  :url="urlToShare"
                  :title="show_name"
                  :description="bio"
              >
                <v-icon
                    title="Share through Facebook"
                    x-large
                    color="blue darken-1">mdi-facebook</v-icon>
              </ShareNetwork>
              <ShareNetwork
                  class="text-decoration-none ml-2 mr-2"
                  network="twitter"
                  :url="urlToShare"
                  :title="show_name"
              >
                <v-icon
                    title="Share through Twitter"
                    x-large
                    color="light-blue lighten-3">mdi-twitter</v-icon>
              </ShareNetwork>
              <ShareNetwork
                  class="text-decoration-none ml-2"
                  network="linkedin"
                  :url="urlToShare"
                  :title="show_name"
              >
                <v-icon
                    title="Share through Linkedin"
                    x-large
                    color="light-blue darken-3">mdi-linkedin</v-icon>
              </ShareNetwork>
            </v-row>

          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <v-snackbar
        v-model="urlCopied"
        :timeout="3000"
        bottom
        color="success"
    >Copied</v-snackbar>
    <v-snackbar
        v-model="isError"
        :timeout="4000"
        top
        color="error"
    >{{errorValue}}</v-snackbar>



  </v-card>
</template>

<script>

import { auth, usersCollection, storage } from '../firebase'
import FirstStep from "../components/FirstStep";
import Profile from "./Profile";
import VueSocialSharing from 'vue-social-sharing'
import Vue from 'vue'

Vue.use(VueSocialSharing);


export default {
  metaInfo: {
    title: 'Your website | tzeani',
    meta: [
      { name: 'description', content: 'Less than 2 minutes is the time it takes you to create a website with tzeani.com. Free, fast, elegant, and simple.' },
      { name: 'robots', content: 'noindex, nonofollow' }
    ]
  },
  name: "Me",
  components: {
    Profile,
    FirstStep
    },
  data() {
    return {
      isError: false,
      errorValue: '',
      madeFirstStep: null,
      showWelcomeDialog: false,
      urlToShare: '',
      show_name: '',
      bio: '',
      urlCopied: false,
      loadedDoc: null,
      oldImageUrl: '',
      oldImageName: '',
      oldCvUrl: ''
    }
  },
  methods: {
    showError(error) {
      this.isError = true
      this.errorValue = error
    },
    copyUrl() {
      navigator.clipboard.writeText(this.urlToShare);
      this.urlCopied = true
    },
    isFirstStep: function() {
      this.madeFirstStep = false;
    },
    notFirstStep() {
      this.madeFirstStep = true;
    },
    justDone(alias, show_name, bio) {
      /*alert(alias + " " + show_name + " " + bio)
      console.log(this.loadedDoc)*/
      this.urlToShare = Vue.prototype.$publicUrl + '/' + alias.toLowerCase()
      this.show_name = show_name
      this.bio = bio
      this.downloadDoc(true)


    },
    downloadCV(component) {
      storage.ref('cv/' + auth.currentUser.uid + '.pdf').getDownloadURL().then(function(url) {
        component.oldCvUrl = url
        component.notFirstStep()
      }).catch(function() {
        //No existe
        component.notFirstStep()
      });
    },
    downloadDoc(afterFirstStep) {
      let docRef = usersCollection.doc(auth.currentUser.uid);

      let component = this

      docRef.get().then(function(doc) {
        if (doc.exists) {
          component.loadedDoc = doc

          if(afterFirstStep) {
            component.showWelcomeDialog = true
          }


          // Buscamos la imagen
          if(doc.data().image_name.length > 0) {
            let oldImageName = doc.data().image_name
            storage.ref('images/' + auth.currentUser.uid + '/' + oldImageName).getDownloadURL().then(function(url) {
              component.oldImageUrl = url
              component.oldImageName = oldImageName
              //if(doc.data().pro && doc.data().pro === true) {
                component.downloadCV(component)
              //} else {
//                component.notFirstStep()
//              }

            }).catch(function(error) {
              //Error, o no existe la imagen
              component.showError(error)
              //if(doc.data().pro && doc.data().pro === true) {
                component.downloadCV(component)
              //} else {
                //component.notFirstStep()
              //}
            });

          } else{
            //if(doc.data().pro && doc.data().pro === true) {
              component.downloadCV(component)
            //} else {
             // component.notFirstStep()
            //}
          }





        } else {
          component.isFirstStep()

        }
      }).catch(function(error) {
        component.showError("We had en error loading your account. Please try again later. If the error persists, please contact us. " + error)
      });
    }
  },
  mounted: function() {



    // User is signed in.
    //var displayName = auth.user.displayName;
    //var email = user.email;
    //var emailVerified = user.emailVerified;
    //var photoURL = user.photoURL;
    //var uid = auth.user.uid;
    //var phoneNumber = user.phoneNumber;
    //auth.user.getIdToken().then(function(/*accessToken*/) {
      /*document.getElementById('sign-in-status').textContent = 'Signed in';
      document.getElementById('sign-in').textContent = 'Sign out';
      document.getElementById('account-details').textContent = JSON.stringify({
        displayName: displayName,
        email: email,
        emailVerified: emailVerified,
        phoneNumber: phoneNumber,
        photoURL: photoURL,
        uid: uid,
        accessToken: accessToken,
        providerData: providerData
      }, null, '  ');*/
      //console.log("Name: " + displayName + "\nEmail: " + email+"\nE-Verfied: "+emailVerified+"\nfoto: "+photoURL+"\nUID: "+uid+"\ntelefono: "+phoneNumber);
    //});

    //Veamos si el usuario completó la primer etapa de registro
    this.downloadDoc(false)

  }
}
</script>

<style>
.welcome {
  background: rgb(38,38,38);
  background: linear-gradient(180deg, rgba(38,38,38,1) 0%, rgba(146,146,146,1) 100%);
}
</style>