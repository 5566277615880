<template>
  <div
      class="fill-height">
    <!--<v-progress-circular
        class="mt-15"
        indeterminate
        color="blue-grey"
        :size="100"
        v-if="!loaded"
    ></v-progress-circular>-->
    <v-card
        :class="`fill-height pb-15 ` + websiteData.style "
        elevation="0" v-if="websiteData != null">
      <ProfileNavbar
        :website-data="websiteData" :image-data="imageData" :cv-data="cvData" :madeChanges="madeChanges" @onDecideChanges="onDecideChanges" @styleChanged="madeChanges = true" @saved="onSaved" @onerror="showError"></ProfileNavbar>

      <v-dialog
          v-model="dialogCropImage"
          :width="get1CardWidth"
          :scrollable="false"
      >
        <v-card>
          <v-toolbar>
            <v-spacer></v-spacer>
            <v-btn @click="crop" class="blue lighten-1 white--text">Finish</v-btn></v-toolbar>
          <cropper
              v-if="imageData.newImageUrl.length > 0"
              backgroundClass="fondoCropper"
              ref="cropper"
              :src="imageData.newImageUrl"
              :stencil-props="{
                aspectRatio: 1
              }"
          />
        </v-card>

      </v-dialog>

      <v-menu
          bottom
          min-width="200px"
          rounded
          offset-y
      >
        <template v-slot:activator="{ on }">


          <v-hover v-slot="{ hover }">



          <v-btn
              id="btnChangeImage"
              style="background: none;"
              icon
              x-large
              v-on="on"
          >
            <v-avatar class="text-center mx-auto py-0 mt-xl-n16 mt-lg-n9 mt-md-n7 mt-sm-n11 mt-n7" :size="getAvatarSize">
              <v-icon
                title="Modify image"
                alt="Modify image"
                :size="getIconAvatarSize"
                v-if="hover && imageData.currentImageUrl.length > 0">mdi-pencil-outline</v-icon>
              <img
                  v-else-if="imageData.currentImageUrl.length > 0"
                  :src="imageData.currentImageUrl"
                  :alt="websiteData.show_name"
              >
              <v-icon
                  color="blue-grey darken-4"
                  title="New image"
                  alt="New image"
                  size="100"
                  v-else>mdi-camera-plus</v-icon>

              <input @change="changedInputImage" style="display: none;" type="file" accept="image/*" id="imageInput">

            </v-avatar>
          </v-btn>

          </v-hover>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <h3>Website image</h3>
              <v-divider class="my-3"></v-divider>
              <v-btn
                  @click="chooseImage"
                  depressed
                  rounded
                  text
                  v-if="imageData.currentImageUrl.length === 0"
              >
                Upload image
              </v-btn>
              <v-btn
                  @click="chooseImage"
                  depressed
                  rounded
                  text
                  v-if="imageData.currentImageUrl.length > 0"
              >
                Modify image
              </v-btn>
              <v-divider
                  v-if="imageData.currentImageUrl.length > 0"
                  class="my-3"></v-divider>
              <v-btn
                  @click="deleteImage"
                  depressed
                  rounded
                  text
                  v-if="imageData.currentImageUrl.length > 0"
              >
                Delete current image
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>


      <v-card
          elevation="0"
          class="mx-auto mt-7"
          :max-width="get1CardWidth" >
        <v-text-field
            class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h5 text-h5 py-xl-3 py-lg-3 py-md-2 py-sm-1 py-0 px-xl-3 px-lg-3 px-md-3 px-sm-2 px-2 font-weight-bold text-center"
            v-model="websiteData.show_name"
            label="Name"
            :dense="denseInput"
            :rules="fullNameRules"
            @change="madeChanges = true"
        ></v-text-field>
      </v-card>





      <v-container
        class="mt-xl-8 mt-lg-5 mt-md-6 mt-sm-3 mt-7"
        :style="getContactContainerStyle" >
        <v-row>
          <v-col
            :class="contactColClass">
            <v-text-field
              title="Email to be displayed"
              v-model="websiteData.email"
              class="text-center"
              label="Displayed email"
              @change="madeChanges = true">
              <v-icon slot="prepend" color="blue-grey">mdi-email</v-icon>
            </v-text-field>
          </v-col>
          <v-col
            :class="contactColClass" >
            <v-text-field
              title="Phone number to be displayed"
              v-model="websiteData.phone"
              class="text-center"
              label="Displayed phone number"
              @change="madeChanges = true">
              <v-icon slot="prepend" color="light-blue accent-2">mdi-cellphone</v-icon>
            </v-text-field>
          </v-col>
          <v-col
              :class="contactColClass">
            <v-text-field
              title="Location to be displayed"
              v-model="websiteData.location"
              class="text-center"
              label="Displayed location"
              @change="madeChanges = true">
              <v-icon slot="prepend" color="deep-orange lighten-1">mdi-map-marker</v-icon>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-card
          class="mx-auto py-xl-7 py-lg-7 py-md-7 py-sm-7 py-7 px-xl-7 px-lg-7 px-md-7 px-sm-7 px-7 mt-xl-7 mt-lg-7 mt-md-3 mt-sm-0 mt-3 text-xl-subtitle-1 text-lg-subtitle-1 text-md-subtitle-1 text-sm-subtitle-1 text-sm-subtitle-1 font-weight-medium"
          style="border-radius: 10px;"
          :max-width="get2CardWidth"
          elevation="8"
          shaped >
        <v-textarea
            counter
            :rules="bioRules"
            v-model="websiteData.bio"
            label="How do you describe yourself?"
            hint="Write a paragraph that best describes you."
            @change="madeChanges = true"
        ></v-textarea>
      </v-card>

      <div class="mt-xl-13 mt-lg-13 mt-md-13 mt-sm-9 mt-9">


        <div
            class="text-xl-h6 text-lg-h6 text-md-h6 text-sm-body-1 text-body-1 mt-xl-10 mt-lg-10 mt-md-10 mt-sm-7 mt-7 font-weight-medium mx-2">
          <v-icon large>mdi-clipboard-account-outline</v-icon>
          CV/Resume
          <v-icon
            v-if="cvData.currentCvUrl.length > 0 && cvData.oldCvUrl !== cvData.currentCvUrl"
            class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2 ml-2"
            :title="`Selected: ` + cvData.newCvName"
            >mdi-check-bold</v-icon>
          <v-btn
            :href="cvData.currentCvUrl"
            target="_blank"
            v-if="cvData.oldCvUrl.length > 0 && cvData.oldCvUrl === cvData.currentCvUrl"
            icon
            elevation="2"
            class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2 ml-2">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn
              v-if="cvData.currentCvUrl.length > 0"
              @click="deleteCv"
              rounded
              class="ml-xl-5 ml-lg-5 ml-md-2 ml-sm-2 ml-2">
            Delete file
          </v-btn>
          <v-btn
            v-if="cvData.currentCvUrl.length === 0"
            @click="chooseCv"
            rounded
            class="ml-xl-5 ml-lg-5 ml-md-2 ml-sm-2 ml-2">
            {{ cvChangeFileText }}
          </v-btn>
          <input @change="changedInputCv" style="display: none;" type="file" accept="application/pdf" id="cvInput">
        </div>

      </div>


      <v-divider
        class="mt-xl-13 mt-lg-13 mt-md-13 mt-sm-9 mt-9"></v-divider>

      <div
        class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h5 text-h5 mt-xl-10 mt-lg-10 mt-md-10 mt-sm-7 mt-7 font-weight-medium mx-2">
        Links</div>

      <v-card
          elevation="0"
          class="mx-auto mt-xl-7 mt-lg-7 mt-md-7 mt-sm-5 mt-7 mb-7"
          :max-width="get1CardWidth" >
        <div v-bind:key="idx" v-for="(link, idx) in websiteData.shownLinks">
          <v-row>
              <v-col
                  class="col-6 pr-0">
                  <v-text-field
                          v-model="link.linkUrl"
                          label="URL"
                          placeholder="Start with http:// or https://"
                          filled
                          rounded
                          dense
                          style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                          :rules="linkUrlRule"
                  >

                      <template v-slot:prepend>
                          <v-select
                                  title="Select the link type"
                                  class="mt-n2 selectLinkType"
                                  style="border-style: none; width: 80px"
                                  dense
                                  :items="availableLinkTypes"
                                  filled
                                  placeholder="Link type"
                                  v-model="link.linkType"
                          >
                              <template v-slot:item="data">
                                  <v-icon class="d-flex justify-center">{{data.item}}</v-icon>
                              </template>

                              <template v-slot:selection="data">
                                  <v-icon class="d-flex justify-center">{{data.item}}</v-icon>
                              </template>

                          </v-select>
                      </template>
                  </v-text-field>
              </v-col>
              <v-col
                  class="col-6 pl-0">
                  <v-text-field
                          v-model="link.linkName"
                          label="Display text"
                          filled
                          rounded
                          dense
                          style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
                          :rules="linkNameRule"
                  >

                      <template v-slot:append-outer>
                          <v-btn
                                  @click="removeShownLink(idx)"
                                  title="Remove link"
                                  class="mt-n1"
                                  icon>
                              <v-icon>mdi-delete</v-icon>
                          </v-btn>
                      </template>
                  </v-text-field>
              </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col
            class="col-6 pr-0"
            >
            <v-text-field
                    label="URL"
                    placeholder="Start with http:// or https://"
                    filled
                    dense
                    style="border-top-right-radius: 0;"
                    v-model="selectedNewLinkUrl"
                    :rules="linkUrlRule"
            >
                <template v-slot:prepend>
                    <v-select
                            title="Select the link type"
                            class="mt-n2 selectLinkType"
                            style="border-style: none; width: 80px"
                            dense
                            :items="availableLinkTypes"
                            filled
                            placeholder="Link type"
                            v-model="selectedNewLinkType"
                    >
                        <template v-slot:item="data">
                            <v-icon class="d-flex justify-center">{{data.item}}</v-icon>
                        </template>

                        <template v-slot:selection="data">
                            <v-icon class="d-flex justify-center">{{data.item}}</v-icon>
                        </template>

                    </v-select>
                </template>

            </v-text-field>

          </v-col>
          <v-col class="col-6 pl-0">
            <v-text-field
                    label="Display text"
                    filled
                    dense
                    style="border-top-left-radius: 0;"
                    v-model="selectedNewLinkDisplayText"
                    :rules="linkNameRule"
            >

                <template v-slot:append-outer>
                    <v-btn
                            @click="addLinkPressed"
                            color="teal darken-3"
                            class="mt-n1 white--text"
                            dense>
                        Add
                    </v-btn>
                </template>
            </v-text-field>

          </v-col>
        </v-row>


      </v-card>

      <div
        v-if="!websiteData.pro && false">
        <v-divider></v-divider>
        <GoPro></GoPro>
      </div>





    </v-card>

    <PlatformFooter></PlatformFooter>

    <v-snackbar
        v-model="SavedNotification"
        :timeout="4000"
        bottom
        color="success"
    >Successfully saved</v-snackbar>
    <v-snackbar
        v-model="isError"
        :timeout="4000"
        top
        color="error"
    >{{errorValue}}</v-snackbar>

    <v-dialog
      v-model="dialogDecideChanges"
      :width="get3CardWidth">
      <v-card>
        <v-card-title
          class="headline justify-center">You have unsaved changes</v-card-title>
        <v-card-actions
          class="py-10 justify-center">
          <v-btn
            outlined
            class="mr-3"
            @click="$router.push('/')">Close anyways</v-btn>
          <v-btn
            class="indigo lighten-1 white--text"
            outlined
            @click="dialogDecideChanges = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



  </div>
</template>

<script>

import ProfileNavbar from "../components/ProfileNavbar";
import GoPro from "../components/GoPro";
import PlatformFooter from "../components/PlatformFooter";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';


export default {
  name: "Profile",
  components: {PlatformFooter, GoPro, ProfileNavbar, Cropper },
  props: ['doc', 'oldImageUrl', 'oldImageName', 'oldCvUrl'],
  data() {
    return {
      madeChanges: false,
      dialogDecideChanges: false,

      paypal: {
        sandbox: '<sandbox client id>',
        production: '<production client id>'
      },

      websiteData: null,
      imageData: {
        newImageName: '',
        newImageUrl: '',
        currentImageUrl: '',
        oldImageUrl: '',
        oldImageName: ''
      },
      cvData: {
        newCvUrl: '',
        newCvName: '',
        currentCvUrl: '',
        oldCvUrl: ''
      },
      cvChangeFileText: 'Upload file',
      //loaded: false,
      SavedNotification: false,
      isError: false,
      errorValue: '',


      fullNameRules: [
        value => !!value || 'Required',
        value => (value && value.trim().length > 0) || 'Required',
      ],
      bioRules: [
        value => !!value || 'Required',
        value => (value && value.trim().length > 0) || 'Required',
        value => (value && value.length >= 50) || 'Minimum 50 characters'
      ],
      linkUrlRule: [
        value => (value.trim().length === 0 || value.trim().length > 0 && (value.toLowerCase().startsWith('http://') || value.toLowerCase().startsWith('https://'))) || 'Must start with http:// or https://'
      ],
      linkNameRule: [
        value => !!value || 'Required',
        value => (value && value.trim().length > 0) || 'Required',
      ],

      availableLinkTypes: ['mdi-web', 'mdi-facebook', 'mdi-twitter', 'mdi-instagram', 'mdi-whatsapp', 'mdi-snapchat', 'mdi-linkedin', 'mdi-cart', 'mdi-github', 'mdi-youtube', 'mdi-twitch', 'mdi-vimeo', 'mdi-spotify', 'mdi-music', 'mdi-pinterest', 'mdi-quora', 'mdi-vk'],
      selectedNewLinkType: 'mdi-web',
      selectedNewLinkUrl: '',
      selectedNewLinkDisplayText: '',

      dialogCropImage: false

    }
  },
  methods: {
    onDecideChanges() {
      this.dialogDecideChanges = true
    },
    showError(error) {
      this.isError = true
      this.errorValue = error
    },
    setWebsiteData(websiteData) {
      this.websiteData = websiteData
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else
        byteString = unescape(dataURI.split(',')[1]);
      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {type:mimeString});
    },
    crop() {
      this.dialogCropImage = false
      const { canvas } = this.$refs.cropper.getResult();
      let dataUrl = canvas.toDataURL()
      this.imageData.currentImageUrl = dataUrl
      this.imageData.newImageUrl = dataUrl
      this.imageData.newImageFile = this.dataURItoBlob(dataUrl)
    },
    addLinkPressed() {
      if(this.selectedNewLinkUrl.trim().length > 0 && this.selectedNewLinkDisplayText.trim().length > 0) {
        if((this.selectedNewLinkUrl.toLowerCase().startsWith('http://') || this.selectedNewLinkUrl.toLowerCase().startsWith('https://'))) {
          this.websiteData.shownLinks.push({
            linkType: this.selectedNewLinkType,
            linkUrl: this.selectedNewLinkUrl,
            linkName: this.selectedNewLinkDisplayText
          })
          this.selectedNewLinkType = 'mdi-web'
          this.selectedNewLinkUrl = ''
          this.madeChanges = true
        }

      }

    },
    removeShownLink(index) {
      this.websiteData.shownLinks.splice(index, 1);
      this.madeChanges = true
    },
    onSaved() {
      this.SavedNotification = true;
      this.madeChanges = false
    },
    chooseImage() {
      let clickEvent = new MouseEvent("click", {
        "view": window,
        "bubbles": true,
        "cancelable": false
      });
      document.getElementById('imageInput').dispatchEvent(clickEvent);
    },
    chooseCv() {
      let clickEvent = new MouseEvent("click", {
        "view": window,
        "bubbles": true,
        "cancelable": false
      });
      document.getElementById('cvInput').dispatchEvent(clickEvent);
    },
    changedInputImage(eve) {
      let component = this
      if (eve.target.files && eve.target.files[0]) {
        let reader = new FileReader();
        reader.onload = function(e) {
          component.imageData.currentImageUrl = e.target.result
          component.imageData.newImageUrl = e.target.result
        }
        reader.readAsDataURL(eve.target.files[0]); // convert to base64 string
        component.imageData.newImageFile = eve.target.files[0]
        component.imageData.newImageName = eve.target.files[0].name
        component.dialogCropImage = true
        this.madeChanges = true
      } else {
        this.showError("We couldn't update your image. Please try again.")
      }
    },
    changedInputCv(eve) {
      let component = this
      if (eve.target.files && eve.target.files[0]) {
        let reader = new FileReader();
        reader.onload = function(e) {
          component.cvData.currentCvUrl = e.target.result
          component.cvData.newCvUrl = e.target.result
        }
        reader.readAsDataURL(eve.target.files[0]); // convert to base64 string
        component.cvData.newCvFile = eve.target.files[0]
        component.cvData.newCvName = eve.target.files[0].name
        this.madeChanges = true
      } else {
        this.showError("We couldn't update your cv/resume. Please try again.")
      }
    },
    deleteImage() {
      this.imageData.newImageName = ''
      this.imageData.newImageUrl = ''
      this.imageData.currentImageUrl = ''
      this.madeChanges = true
    },
    deleteCv() {
      this.cvData.newCvUrl = ''
      this.cvData.currentCvUrl = ''
      this.cvChangeFileText = "Upload file"
      this.madeChanges = true
    }
  },
  mounted() {
    /*if(this.doc) {
      this.setWebsiteData(this.doc.data())
    }*/

    this.imageData.currentImageUrl = ''
    this.imageData.newImageUrl = ''
    this.imageData.newImageName = ''

    this.cvData.currentCvUrl = ''
    this.cvData.newCvUrl = ''

    if(this.oldImageUrl.length > 0) {
      this.imageData.currentImageUrl = this.oldImageUrl
      this.imageData.oldImageUrl = this.oldImageUrl
      this.imageData.oldImageName = this.oldImageName
    }

    if(this.oldCvUrl.length > 0) {
      this.cvData.currentCvUrl = this.oldCvUrl
      this.cvData.oldCvUrl = this.oldCvUrl
      this.cvChangeFileText = "Change file"
    }

    let data = this.doc.data()
    data.shownLinks = []



    //this.shownLinks = []
    /*if(data.pro === true) {
      //let component = this
      data.links.forEach(function (link) {
        data.shownLinks.push(link)
      })
    } else if(data.links.length > 0) {
      data.shownLinks.push(data.links[0])
    }*/
    data.links.forEach(function (link) {
        data.shownLinks.push(link)
    })

    this.websiteData = data








    /*let component = this
    usersCollection.doc(auth.currentUser.uid).get()
        .then(function(doc) {
          if (doc.exists) {
            component.setWebsiteData(doc.data())
          } else {
            component.$router.push('/')
            component.showError("We had en error loading your account. Please try again later. If the error persists, please contact us.")
          }
          component.setLoaded()
        })
        .catch(function(error) {
          component.showError("We had en error loading your account. Please try again later. If the error persists, please contact us. " + error)
          component.setLoaded()
        });*/

  },
  computed: {
    get1CardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '500px'
        case 'md': return '700px'
        case 'lg': return '700px'
        case 'xl': return '700px'
      }
      return '700px'
    },
    get2CardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '500px'
        case 'md': return '800px'
        case 'lg': return '1000px'
        case 'xl': return '1000px'
      }
      return '1000px'
    },
    get3CardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '500px'
        case 'md': return '50px'
        case 'lg': return '500px'
        case 'xl': return '500px'
      }
      return '500px'
    },
    getContactContainerStyle() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'max-width: 90%'
          case 'sm': return 'max-width: 500px'
          case 'md': return 'max-width: 800px'
          case 'lg': return 'max-width: 1000px'
          case 'xl': return 'max-width: 1000px'
        }
        return 'max-width: 1000px'
    },
    denseInput() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return true
        case 'lg': return false
        case 'xl': return false
      }
      return false
    },
    getAvatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '120'
        case 'sm': return '120'
        case 'md': return '120'
        case 'lg': return '130'
        case 'xl': return '150'
      }
      return false
    },
    getIconAvatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '80'
        case 'sm': return '80'
        case 'md': return '80'
        case 'lg': return '90'
        case 'xl': return '110'
      }
      return false
    },
    contactColClass() {
      let retornar = "py-xl-2 py-lg-2 py-md-2 py-sm-2 py-0 "
      retornar += 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'
      return retornar
    }/*,
    pencilStyle() {
      return styleData[this.websiteData.style].pencilStyle
    },
    mainStyle() {
      return styleData[this.websiteData.style].mainStyle
    }*/
  }
}
</script>

<style>
  .text-center input {
    text-align: center;
  }
  #btnChangeImage::before {
    background: none;
  }

  .selectLinkType.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
  }
  .selectLinkType.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
  }

  .fondoCropper {
    background-color: #ffffff;
  }


</style>