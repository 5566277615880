<template>
  <div>
    <v-app-bar
        color="transparent"
        dark
        elevation="0"
        absolute
    >



      <v-app-bar-nav-icon
          @click="drawer = true"
          v-if="showNavigationDrawer"></v-app-bar-nav-icon>

      <GoHomeNavbar></GoHomeNavbar>

      <v-spacer></v-spacer>


      <v-btn v-if="!showNavigationDrawer" text @click="clickedAbout">
        about
      </v-btn>

      <v-dialog
          v-if="!showNavigationDrawer"
          transition="dialog-top-transition"
          max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              class="mr-15"
              v-bind="attrs"
              v-on="on"
              @click="beforeLogin($event)"
              >
            log in
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card class="pb-15">
            <v-card-actions
              class="justify-end">
              <v-btn
                  class="float-right"
                  text
                  @click="dialog.value = false"
              >X</v-btn>
            </v-card-actions>

            <div class="text-h2">log in</div>
            <div class="text-h6 mt-5 mb-10">Select your preferred authentication method</div>

            <Access></Access>

          </v-card>
        </template>
      </v-dialog>




    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                tzeani
              </v-list-item-title>
              <!--<v-list-item-subtitle>
                subtext
              </v-list-item-subtitle>-->
            </v-list-item-content>
          </v-list-item>

          <v-item></v-item>

          <v-list-item @click="clickedHome">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>


          <v-list-item @click="clickedAbout">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>


          <v-dialog
              transition="dialog-top-transition"
              max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  @click="beforeLogin($event)">
                <v-list-item-icon>
                  <v-icon>mdi-login</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Log in</v-list-item-title>
              </v-list-item>

            </template>
            <template v-slot:default="dialog">
              <v-card class="pb-15">
                <v-card-actions
                    class="justify-end">
                  <v-btn
                      class="float-right"
                      text
                      @click="dialog.value = false"
                  >X</v-btn>
                </v-card-actions>

                <div class="text-h2">log in</div>
                <div class="text-h6 mt-5 mb-10">Select your preferred authentication method</div>

                <Access></Access>

              </v-card>
            </template>
          </v-dialog>



        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { auth } from '../firebase'
import Access from "./Access";
import GoHomeNavbar from "./GoHomeNavbar";
export default {
  name: "welcomeNavbar",
  components: {GoHomeNavbar, Access},
  data() {
    return {
      drawer: false
    }
  },
  methods: {
    clickedHome() {
      this.$emit('clickedHome');
      this.drawer = false
    },
    clickedAbout() {
      this.$emit('clickedAbout');
      this.drawer = false
    },
    beforeLogin(event) {
      if(auth.currentUser) {
        this.$router.push('/me')
        event.preventDefault()
      }

    }
  },
  computed: {
    showNavigationDrawer() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return false
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>