<template>
  <v-main class="fill-height">
    <v-card class="fill-height" elevation="0">
      <welcomeNavbar @clickedAbout="scrollToAbout" @clickedHome="scrollToWelcome" />
      <welcome class="welcomeSection" :customTitle="receivedTitle" :customSubtitle="receivedSubtitle" />
      <about class="about" />
      <PlatformFooter class="mt-md-15"></PlatformFooter>
    </v-card>
  </v-main>
</template>

<script>
// @ is an alias to /src
import welcome from '@/components/welcome.vue'
import about from '@/components/about.vue'
import welcomeNavbar from '@/components/welcomeNavbar.vue'
import PlatformFooter from "../components/PlatformFooter";


export default {
  metaInfo: {
    title: 'tzeani | Create fast & simple website',
    meta: [
      { name: 'description', content: 'Less than 2 minutes is the time it takes you to create a website with tzeani.com. Free, fast, elegant, and simple.' },
      { name: 'robots', content: 'index, follow' }
    ]
  },
  name: 'Home',
  props: ['Title', 'Subtitle'],
  data() {
    return {
      receivedTitle: this.Title,
      receivedSubtitle: this.Subtitle
    }
  },
  components: {
    PlatformFooter,
    welcome,
    about,
    welcomeNavbar
  },
  beforeMount() {

  },
  mounted() {
    if(this.Title == null || this.Subtitle == null) {
      this.receivedTitle = "Still don't have your own website?"
      this.receivedSubtitle = "To succeed in the 21st century you have to be online. Show to the internet who you are."
    }
  },
  methods: {
    scrollToWelcome() {
      const welcomeSection = this.$el.getElementsByClassName('welcomeSection')[0];

      if (welcomeSection) {
        // Use el.scrollIntoView() to instantly scroll to the element
        welcomeSection.scrollIntoView({behavior: 'smooth'});
      }
    },
    scrollToAbout() {
      const aboutSection = this.$el.getElementsByClassName('about')[0];

      if (aboutSection) {
        // Use el.scrollIntoView() to instantly scroll to the element
        aboutSection.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>

<style>

</style>