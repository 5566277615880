<template>
  <v-app-bar
      extended
      flat
      class="welcome"
      dark
      elevation="0"
  >


    <GoHomeNavbar></GoHomeNavbar>



  </v-app-bar>
</template>

<script>
import GoHomeNavbar from "./GoHomeNavbar";
export default {
  name: "SignupNavbar",
  components: {GoHomeNavbar}
}
</script>

<style scoped>

</style>