<template>
  <div id="firebaseui-auth-container"></div>
</template>

<script>
import firebase from "firebase";
var firebaseui = require('firebaseui');
/*import firebaseui from "firebaseui";*/
import "firebaseui/dist/firebaseui.css"



export default {
  name: "Access",
  components: {},
  mounted() {

    // TODO Mostrar cartel o algo tipo Loading mientras decide si ya hay usuario logeado

    let ui = firebaseui.auth.AuthUI.getInstance()
    if(!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }

    var uiConfig = {
      signInSuccessUrl: '/me',
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID/*,
        firebase.auth.EmailAuthProvider.PROVIDER_ID*/
      ]/*,
        // Terms of service url/callback.
        tosUrl: '<your-tos-url>',
        // Privacy policy url/callback.
        privacyPolicyUrl: function () {
          window.location.assign('<your-privacy-policy-url>');
        }*/
    };

    ui.start("#firebaseui-auth-container", uiConfig);






  }
}
</script>

<style scoped>
</style>