<template>
  <v-app-bar
      extended
      flat
      class="welcome"
      dark
      elevation="0"
  >



    <GoHomeNavbar></GoHomeNavbar>

    <v-spacer></v-spacer>

    <v-menu
        v-if="showOverflow"
        left
        bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
            @click="() => {}"
        >
          <v-list-item-title @click="logOut">log out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>




    <v-btn v-if="!showOverflow" text class="mr-15" @click="logOut">
      log out
    </v-btn>

    <v-snackbar
        v-model="isError"
        :timeout="4000"
        top
        color="error"
    >{{errorValue}}</v-snackbar>

  </v-app-bar>
</template>

<script>
import firebase from "firebase";
import GoHomeNavbar from "./GoHomeNavbar";



export default {
  name: "signedInNavbar",
  components: {GoHomeNavbar},
  data() {
    return {
      isError: false,
      errorValue: ''
    }
  },
  methods: {
    showError(error) {
      this.isError = true
      this.errorValue = error
    },
    logOut() {
      let router = this.$router;
      firebase.auth().signOut().then(function() {
        router.push('/')
      }, function(error) {
        this.showError('Log Out Error: ' + error)
      });
    }
  },
  computed: {
    showOverflow() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return false
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>