<template>
  <v-app-bar
      extended
      flat
      :class="websiteStyle"
      dark
      elevation="0"
  >




  </v-app-bar>
</template>

<script>

export default {
  name: "WebsiteNavbar",
  props: ['websiteStyle']
}
</script>

<style scoped>
  .Natural {
    background: linear-gradient(180deg, #59b9d6 0%, rgb(129 222 209) 100%);
  }
  .Night {
    background: linear-gradient(rgb(0 0 0) 0%, rgb(27 33 62) 100%);
  }
  .Pink {
    background: linear-gradient(rgb(195 125 204) 0%, rgb(247 120 255) 100%);
  }
</style>