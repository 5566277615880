<template>
  <v-card
      class="fill-height" elevation="0">
    <SignedInNavbar></SignedInNavbar>
    <v-card
        class="mx-auto pb-7 pt-xl-7 pt-lg-7 pt-md-7 pt-7 px-xl-7 px-lg-7 px-md-7 px-sm-7 px-2 mt-xl-n16 mt-lg-n16 mt-md-n11 mt-sm-n11 mt-n11 mb-16"
        :max-width="getCardWidth">
      <div class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h6 text-h6 font-weight-medium text-left mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-3">What's the name you want to show?</div>
      <v-text-field
          class="text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1 text-body-1"
          :dense="denseInput"
          v-model="show_name"
          style="max-width: 300px"
          solo
          label="Name"
          clearable
          :rules="fullNameRules"
      ></v-text-field>

      <div class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h6 text-h6 font-weight-medium text-left mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-3">Think a name for your web</div>
      <div class="text-xl-subtitle-1 text-lg-subtitle-1 text-md-subtitle-1 text-sm-subtitle-1 text-sm-subtitle-1 text-left mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-3">You won't be able to change it.</div>

      <v-text-field
          class="alias text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1 text-body-1"
          @change="verifyAlias"
          @keyup="verifyAlias"
          :dense="denseInput"
          v-model="alias"
          style="max-width: 300px"
          solo
          label="your_custom_name"
          :rules="aliasRules"
          prefix="tzeani.com/"
      >
        <template v-slot:append-outer>
          <v-progress-circular
              v-if="isValidatingAlias"
              indeterminate
              color="blue-grey"
              :size="20"
          ></v-progress-circular>
          <v-icon slot="activator" class="teal--text text--accent-4" v-else-if="!isValidatingAlias && showValidationAliasIcon && isAliasValid"
          title="The name is free to use">mdi-check</v-icon>
          <v-icon slot="activator" class="yellow--text text--darken-3" v-else-if="!isValidatingAlias && showValidationAliasIcon && !isAliasValid"
          title="The name is already taken">mdi-close</v-icon>
        </template>
      </v-text-field>

      <div class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h6 text-h6 font-weight-medium text-left mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-3">How do you describe yourself?</div>
      <v-textarea
          class="mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-3"
          v-model="bio"
          :rules="bioRules"
          solo
          counter
          name="input-7-4"
          label="Write a paragraph that best describes you."
      ></v-textarea>

      <v-btn
        :loading="publishing"
        class="blue-grey darken-3 white--text"
        @click="publish">
        Publish website

      </v-btn>



    </v-card>
    <v-snackbar
        v-model="isError"
        :timeout="4000"
        top
        color="error"
    >{{errorValue}}</v-snackbar>
  </v-card>
</template>

<script>
import { auth, db, usersCollection, aliasCollection } from '../firebase'
import SignedInNavbar from "./signedInNavbar";

export default {
  name: "FirstStep",
  components: {SignedInNavbar},
  data() {
    return {
      isError: false,
      errorValue: '',
      show_name: null,
      alias: null,
      lastValidAlias: null,
      showValidationAliasIcon: false,
      isAliasValid: false,
      aliasTimeout: null,
      isValidatingAlias: false,
      bio: null,
      publishing: false,
      fullNameRules: [
        value => !!value || 'Required',
        value => (value && value.trim().length > 0) || 'Required',
      ],
      aliasRules: [
        value => !!value || 'Required.',
        value => (value && value.trim().length > 0) || 'Required',
        value => (value && /^([a-z0-9_]+)$/.test(value.toString())) || 'Only lower case, numbers and underscore'
      ],
      bioRules: [
        value => !!value || 'Required',
        value => (value && value.trim().length > 0) || 'Required',
        value => (value && value.length >= 50) || 'Minimum 50 characters'
      ]
    }
  },
  methods: {
    showError(error) {
      this.isError = true
      this.errorValue = error
    },
    verifyAlias(alias) {
      if(alias != this.lastValidAlias) {
        //Debo verificar si este nuevo alias está disponible
        //Demosle 1.2 segundos de espera
        if(this.aliasTimeout != null) {
          clearTimeout(this.aliasTimeout)
          this.aliasTimeout = null
        }

        let component = this

        if(component.alias && component.alias.trim().length > 0) {
          this.aliasTimeout = setTimeout(function(){
            component.isValidatingAlias = true

            let aliasRef = aliasCollection.doc(component.alias.toLowerCase());
            aliasRef.get().then(function(doc) {
              component.showValidationAliasIcon = true

              if (doc.exists) {
                //Ya existe
                component.isAliasValid = false
              } else {
                //No existe
                component.isAliasValid = true
                component.lastValidAlias = alias
              }
              clearTimeout(component.aliasTimeout)
              component.aliasTimeout = null
              component.isValidatingAlias = false
            }).catch(function(error) {
              component.showError(error)
              clearTimeout(component.aliasTimeout)
              component.aliasTimeout = null
              component.isValidatingAlias = false
            });




          }, 1200);
        } else {
          component.showValidationAliasIcon = false
        }



      }
    },
    publish() {
      if (this.show_name && this.show_name.trim().length > 0) {
        if (this.alias && this.alias.trim().length > 0 && /^([a-z0-9_]+)$/.test(this.alias.toString())) {
          if(!this.isValidatingAlias && this.isAliasValid) {
            if (this.bio && this.bio.trim().length >= 50) {
              let component = this

              this.publishing = true

              //De forma transaccional vamos a subir el alias y luego el usuario entero
              let batch = db.batch();

              //Seteamos alias
              let aliasRef = aliasCollection.doc(this.alias.toLowerCase());
              batch.set(aliasRef, {uid: auth.currentUser.uid});

              //Seteamos usuario
              let usersRef = usersCollection.doc(auth.currentUser.uid);
              batch.set(usersRef, {
                show_name: component.show_name,
                alias: component.alias,
                bio: component.bio,
                email: auth.currentUser.email,
                phone: auth.currentUser.phoneNumber,
                location: '',
                links: [],
                image_name: '',
                style: 'Natural'
              });

              // Commit the batch
              batch.commit()
                .then(function () {
                  component.publishing = true
                  component.$emit('done', component.alias, component.show_name, component.bio)
                })
                .catch(function (error) {
                  component.publishing = true
                  component.showError("Error: "+error)
                });


              /*usersCollection.doc(auth.currentUser.uid).set({
                show_name: component.show_name,
                alias: component.alias,
                bio: component.bio
              })
                  .then(function() {
                    component.$emit('done', component.alias, component.show_name, component.bio)
                  })
                  .catch(function(error) {
                    component.showError("Error: "+error)
                  });*/
            } else {
              this.showError('"Bio" field must have minimum 50 characters')
            }
          } else {
            this.showError('The website name is already taken')
          }
        } else {
          this.showError('"Alias" field cannot be empty and can only contain letters and numbers')
        }
      } else {
        this.showError('"Name" field cannot be empty')
      }

    }
  },
  computed: {
    getCardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '500px'
        case 'md': return '700px'
        case 'lg': return '700px'
        case 'xl': return '700px'
      }
      return '700px'
    },
    denseInput() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
      return false
    }
  }
}
</script>

<style>
</style>