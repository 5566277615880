<template>
  <!--  pt-15
-->
  <v-card
      class="fill-height  "
      elevation="0">

    <SignupNavbar></SignupNavbar>

    <v-card
        class="mx-auto py-15 mt-xl-n16 mt-lg-n16 mt-md-n11 mt-sm-n11 mt-n11 mb-15 px-2"
        :max-width="getCardWidth">
      <div class="text-xl-h3 text-lg-h3 text-md-h3 text-sm-h3 text-h4 font-weight-medium">It's super easy</div>
      <div class="text-xl-h5 text-lg-h5 text-md-h5 text-sm-h5 text-h6 mt-6">First, create an account so only you can control your website.</div>
      <div class="text-xl-h6 text-lg-h6 text-md-h6 text-sm-h6 text-body-1 my-6">Select your preferred authentication method.</div>
      <Access></Access>
    </v-card>




  </v-card>
</template>

<script>

import Access from "../components/Access";
import SignupNavbar from "../components/SignupNavbar";



export default {
  metaInfo: {
    title: 'Sign up | tzeani',
    meta: [
      { name: 'description', content: 'Sign up to create a website with tzeani.com. Free, fast, elegant, and simple.' },
      { name: 'robots', content: 'index, follow' }
    ]
  },
  name: "SignUp",
  components: {SignupNavbar, Access},
  computed: {
    getCardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '90%'
        case 'sm': return '500px'
        case 'md': return '700px'
        case 'lg': return '700px'
        case 'xl': return '700px'
      }
      return '700px'
    }
  }
}
</script>

<style scoped>

</style>