import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SignUp from "../views/SignUp";
import Me from "../views/Me";
import { auth } from '../firebase'
import Website from "../views/Website";
import Pro from "../views/Pro";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      guest: true
    }
  },
  {
    path: '/me',
    name: 'Me',
    component: Me,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pro',
    name: 'Pro',
    component: Pro,
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/:alias',//([a-z0-9_]+)',
    name: 'Website',
    component: Website
  }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const requiresGuest = to.matched.some(record => record.meta.guest)
  if (requiresAuth && !auth.currentUser) {
      next('/signup')
  } else if(requiresGuest && auth.currentUser) {
      next('/me')
  } else {
    next()
  }
})


export default router
